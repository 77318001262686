import { useQuery } from '@apollo/client';
import { Box, Grid } from '@mui/material';
import { Plus } from '@styled-icons/bootstrap/Plus';
import { Edit } from '@styled-icons/boxicons-solid/Edit';
import PartnerForm from 'components/Partners/Form/PartnerForm';
import { ButtonContainer, DialogInner, FormContainer } from 'components/Partners/Partners.styles';
import { usePartnerMutations } from 'components/Partners/hooks';
import { useImpactClaims } from 'components/hooks';
import { PartnersSchema } from 'constants/schemas';
import { AlertDialog, CardContainer, DialogDefault, ThemeButton } from 'designSystem';
import { Form, Formik } from 'formik';
import { GET_PARTNER } from 'graphql/queries';
import React, { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IDefaultDialogProps } from 'types/dialog.types';
import { ImpactClaim } from 'types/impactClaimTypes';
import { Partner } from 'types/partner.types';
import { removeGraphConnections } from 'utils/graphConnections';
import PartnerImpactClaimList from '../ImpactClaims/PartnerImpactClaimList';

export interface IPartnerFormDialogProps extends IDefaultDialogProps {
  partner?: Partner;
  isPartner?: boolean;
  // Connecting to a company is only possible when creating a new partner of type farm (this will hide the type selection)
  connectToCompanyId?: string;
  /*
   * Callback will be called after the creation returned result (success or error)
   * By default the user will be redirected to the partner overview
   */
  onPartnerCreated?: (partnerId: string) => void;
}

const PartnerFormDialog: FC<IPartnerFormDialogProps> = ({
  open,
  partner,
  connectToCompanyId,
  isPartner,
  onClose,
  onPartnerCreated,
}) => {
  const navigate = useNavigate();
  const [unsavedChangesModal, setUnsavedChangesModal] = useState(false);
  const { handleCreatePartner, handleUpdatePartner } = usePartnerMutations({ onClose });
  const isCreatingNew: boolean = !partner;

  // Fetches the partner, the initially passed partner will not change when the cache / state changes
  // Thats why the partner is being fetched so always the latest updated partner is present here.
  const { data } = useQuery<{ partner: Partner }>(GET_PARTNER, {
    skip: !partner,
    variables: {
      id: partner?.id,
    },
  });

  const partnerData: Partner | undefined = data?.partner;

  // Passes the partner to the useImpactClaimshook
  // This ensures the partner cache will be updated by the hook if the claim is deleted.
  const { actions, deletableClaim, setDeletableClaim, handleDelete } = useImpactClaims(partnerData);

  const impactClaims: ImpactClaim[] = partnerData?.impactClaims
    ? removeGraphConnections(partnerData?.impactClaims)
    : [];

  /*
   * Either create or update the partner
   * Create callback will be called after the creation returned result (success or error)
   * By default the user will be redirected to the partner overview which can be overwritten by the callback
   */
  const handleSubmitClick = async (values: Partner) => {
    if (isCreatingNew) {
      handleCreatePartner({ ...values, companyId: connectToCompanyId }).then(({ data }) => {
        const createPartner = data?.createPartner;
        if (createPartner?.partner?.id) {
          onPartnerCreated
            ? onPartnerCreated(createPartner.partner.id)
            : navigate(`/partners/${createPartner.partner.id}`);
        }
      });
    } else {
      handleUpdatePartner(values);
    }
  };

  return (
    <DialogDefault
      title={isCreatingNew ? 'New partner' : 'Edit partner'}
      onClose={onClose}
      open={open}
      fullWidth
      icon={isCreatingNew ? Plus : Edit}
      maxWidth="lg"
      background="#fff"
      preventCloseOnBackground
      data-cy="partner-form-dialog"
    >
      <Formik
        //@ts-ignore TODO: Fix this issue
        initialValues={{
          ...PartnersSchema.default(),
          ...(partner || {}),
          ...(isCreatingNew ? (connectToCompanyId ? { type: 'FARM' } : { type: 'SUPPLIER' }) : {}),
        }}
        validateOnBlur={false}
        validateOnChange={true}
        validationSchema={PartnersSchema}
        onSubmit={handleSubmitClick}
      >
        {({ isSubmitting }) => (
          <Form>
            <DialogInner container>
              <FormContainer item xs={6}>
                <Box px={4} pb={4} zIndex={1} position="relative">
                  <PartnerForm hideTypeSelection={connectToCompanyId !== undefined} />
                </Box>
              </FormContainer>
              <Grid item xs={6}>
                <Box display="flex" flexDirection="column" height="100%">
                  {!isCreatingNew && partnerData && !isPartner && (
                    <Box px={4}>
                      <PartnerImpactClaimList
                        partner={partnerData}
                        impactClaims={impactClaims}
                        actions={actions}
                      />
                    </Box>
                  )}

                  {isCreatingNew && !isPartner && (
                    <Box p={3}>
                      <CardContainer hasBorder>
                        Once you have created this partner, you can add impact claims here.
                      </CardContainer>
                    </Box>
                  )}

                  <ButtonContainer>
                    <ThemeButton color="BLUE_ICE" size="large" onClick={onClose}>
                      Cancel
                    </ThemeButton>

                    <ThemeButton
                      data-cy="submit-partner-form-btn"
                      loading={isSubmitting}
                      color="YELLOW"
                      type="submit"
                      size="large"
                    >
                      {isCreatingNew ? 'Save' : 'Apply Changes'}
                    </ThemeButton>
                  </ButtonContainer>
                </Box>
              </Grid>
            </DialogInner>
          </Form>
        )}
      </Formik>

      <AlertDialog
        onClose={() => setUnsavedChangesModal(false)}
        title="Unsaved Changes"
        open={unsavedChangesModal}
        text="You have some unsaved changes that may get lost. Do you want to stay and finish the changes?"
        onSubmit={onClose}
        onCancel={() => setUnsavedChangesModal(false)}
        cancelText="Stay on page"
        submitText="Discard changes"
        preventCloseAfterSubmit={undefined}
        displayCloseButton={undefined}
      />

      <AlertDialog
        onClose={() => setDeletableClaim(null)}
        title="Delete impact claim"
        open={!!deletableClaim}
        text="Deleting this claim will remove it from any content."
        onSubmit={() => {
          handleDelete(deletableClaim);
        }}
        onCancel={() => setDeletableClaim(null)}
        submitText="Delete"
      />
    </DialogDefault>
  );
};

export default PartnerFormDialog;
