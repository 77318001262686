import { Box, DialogActions, styled } from '@mui/material';
import { mergeDefaults } from 'components/Forms/utils';
import { useDialog } from 'components/hooks';
import { StyledDialogContent, TopBackground } from 'components/Product/Create/styles';
import { InlineHelperText } from 'components/Product/InlineHelperText/InlineHelperText';
import { farmSchema, siteSchema } from 'constants/schemas/sites.schema';
import { DialogDefault, ThemeButton } from 'designSystem';
import { Form, Formik, FormikProps } from 'formik';
import { ICreateSiteInput, IUpdateSiteInput } from 'graphql/mutations/types/site-mutation.types';
import isEqual from 'lodash/isEqual';
import React, { FC, useRef } from 'react';
import { Booleanish, booleanish } from 'types/booleanish.types';
import { ImageVariant } from 'types/commonTypes';
import { IDefaultDialogProps } from 'types/dialog.types';
import { Partner } from 'types/partner.types';
import { IFarmSite, ISite, LandOwnershipType, SiteType } from 'types/sites.types';
import { Coordinates } from 'types/types';
import useSiteMutations from './hooks/useSiteMutations';
import SiteForm from './SiteForm';

interface IAddEditSiteDialogProps extends IDefaultDialogProps {
  /** If passed its editing the existing site */
  site?: ISite;
  /**
   * Always assigns the site to this partner
   */
  partner?: Partner;
  /**
   * Will connect the site to this site cluster
   */
  siteClusterId?: string;
  /**
   * Callback function that is called when a site is created
   */
  onSiteCreated?: (site: ISite) => void;
}

interface FarmValues extends IFarmSite {
  rawMaterialId: string;
  size: number;
  numberOfFarmers?: number;
  weight?: number;
  additionalRawMaterialIds?: string[];
  landOwnership?: LandOwnershipType;
}

export interface DefaultFormValues {
  siteType: SiteType;
  title: string;
  locationCoordinates: Coordinates;
  locationName: string;
  image?: ImageVariant;
  partner?: Partner;
  externalId?: string;
}

export type FarmFormValues = DefaultFormValues & { siteType: SiteType.FARM; farmData: FarmValues };

export type FormValues =
  | (DefaultFormValues & {
      siteType: Exclude<SiteType, SiteType.FARM>;
    })
  | FarmFormValues;

const StyledDialogActions = styled(DialogActions)<{ 'has-shadow': booleanish }>(
  ({ theme, 'has-shadow': hasShadow }) => ({
    padding: theme.spacing(3, 5, 4),
    justifyContent: 'space-between',
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,

    ...(hasShadow === 'true' && {
      padding: theme.spacing(3, 5),
      background: theme.custom.themeColors.white,
      boxShadow: theme.custom.shadows[4],
    }),
  })
);

const AddEditSiteDialog: FC<IAddEditSiteDialogProps> = ({
  site,
  partner,
  open,
  siteClusterId,
  onClose,
  onSiteCreated,
}) => {
  const { createSite, updateSite } = useSiteMutations();
  const { openDialog } = useDialog();

  const isEdit = site !== undefined;

  const formRef = useRef<FormikProps<FormValues>>(null);
  const initialValues = mergeDefaults(siteSchema.default(), {
    title: site?.title,
    image: site?.image,
    partner: partner || site?.partner,
    siteType: site?.siteType,
    locationCoordinates: site?.locationCoordinates,
    locationName: site?.locationName,
    externalId: site?.externalId,
    farmData:
      site?.siteType === SiteType.FARM
        ? mergeDefaults(farmSchema.default(), {
            rawMaterialId: site?.rawMaterialId,
            size: site?.size,
            numberOfFarmers: site?.numberOfFarmers,
            additionalRawMaterialIds: site?.additionalRawMaterialIds,
            landOwnership: site?.landOwnership,
          })
        : undefined,
  });

  const handleSubmit = async (values: FormValues) => {
    if (!isEdit) {
      const input: ICreateSiteInput = {
        siteType: values.siteType,
        title: values.title,
        image: values.image?.id ? { imageId: values.image.id } : undefined,
        partnerId: partner?.id || values.partner?.id,
        locationCoordinates: values.locationCoordinates,
        locationName: values.locationName,
        externalId: values.externalId,
        ...(values.siteType !== SiteType.CLUSTER && siteClusterId ? { siteClusterId } : {}),
        ...(values.siteType === SiteType.FARM ? { farmInput: values.farmData } : {}),
        ...(values.siteType === SiteType.CLUSTER ? { siteClusterInput: { siteIds: [] } } : {}),
      };
      const site = await createSite({ variables: { input } });
      if (site.data?.createSite.site) {
        onSiteCreated?.(site.data.createSite.site);
      }
    } else {
      const input: IUpdateSiteInput = {
        siteType: values.siteType,
        title: values.title,
        image: values.image?.id ? { imageId: values.image.id } : null,
        partnerId: partner?.id || values.partner?.id || null,
        locationCoordinates: values.locationCoordinates,
        locationName: values.locationName,
        externalId: values.externalId || null,
        ...(values.siteType !== SiteType.CLUSTER && siteClusterId ? { siteClusterId } : {}),
        ...(values.siteType === SiteType.FARM ? { farmInput: values.farmData } : {}),
        ...(values.siteType === SiteType.CLUSTER ? { siteClusterInput: { siteIds: [] } } : {}),
      };
      await updateSite({ variables: { id: site.id, input } });
    }
    onClose?.();
  };

  // If there are unsaved changes, ask the user if they want to leave the page
  const handleClose = () => {
    if (formRef.current && !isEqual(formRef.current.values, initialValues)) {
      openDialog({
        type: 'ALERT',
        props: {
          title: 'Unsaved changes',
          text: 'Are you sure you want to close this window? All unsaved changes will be lost and you will not be able to undo this action.',
          submitText: 'Close',
          itemTitle: formRef.current?.values?.title,
          displayCloseButton: true,
          onSubmit: onClose,
          onCancel: () => undefined,
        },
      });
    } else {
      onClose?.();
    }
  };

  return (
    <DialogDefault
      title={!site ? 'Add new site' : 'Edit Site'}
      data-cy="add-edit-site-dialog"
      open={open}
      fullWidth
      iconName={!site ? 'plus' : 'edit'}
      maxWidth="md"
      onClose={handleClose}
    >
      <Formik
        innerRef={formRef}
        initialValues={initialValues}
        enableReinitialize
        validateOnChange
        validationSchema={siteSchema}
        isInitialValid={false}
        onSubmit={handleSubmit}
      >
        {({ values, isSubmitting, isValid }) => (
          <Form>
            <TopBackground />
            <StyledDialogContent>
              <Box display="flex" alignItems="center" mb={2} position="relative">
                <InlineHelperText
                  variant="INFO"
                  helperText="A site describes where a supply chain activity takes place, like a farm, processing plant or factory."
                />
              </Box>
              <SiteForm />
            </StyledDialogContent>
            <Box mt={7} />
            <StyledDialogActions has-shadow={Booleanish(values.siteType === 'FARM')}>
              <ThemeButton color="BLUE_ICE" size="large" onClick={handleClose}>
                Cancel
              </ThemeButton>
              <ThemeButton
                loading={isSubmitting}
                disabled={!isValid}
                color="YELLOW"
                size="large"
                type="submit"
              >
                Save
              </ThemeButton>
            </StyledDialogActions>
          </Form>
        )}
      </Formik>
    </DialogDefault>
  );
};

export default AddEditSiteDialog;
